import React, { lazy, Suspense, useEffect,useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
// import NotFound from "views/shared-component/404";
import Image from "helpers/Image";

// Layouts
import AuthLayouts from "layouts/AuthLayouts";
import AppLayout from "layouts/CommonLayout";
import { useProfileDetails } from "store/hooks/profile/profile";
import { useContextHelper } from "helpers/ContextHelper";
import globalRouter from "globalRouter.ts";
import { message } from "antd";


// Report & Performance Routes
const Dashboard = lazy(() => import("./views/app/dashboard/index"));

// System Configuration Routes
const UserManagement = lazy(() => import("./views/app/userAndDepartment/user"));
const DepartmentManagement = lazy(() => import("./views/app/userAndDepartment/department"));
// const TemplateVersion = lazy(() => import("./views/app/templateVersion"));
const Role = lazy(() => import("./views/app/userAndDepartment/role/role"))
const NotificationManagement = lazy(() => import("./views/app/notificationManagement/index"));
const Holiday = lazy(() => import("./views/app/userAndDepartment/holiday"))
const Shifts = lazy(() => import("./views/app/userAndDepartment/shift"))

const MachineList = lazy(() => import("./views/app/machineList/machine"));
const SubMachineList = lazy(() => import("./views/app/machineList/subMachine"));
const SubArchieve = lazy(() => import("./views/app/machineList/Archieve"));

const InspectionRecord = lazy(() => import("./views/app/MachineInspection/machineList/components/record"))
const InspectionPending = lazy(() => import("./views/app/MachineInspection/machineList/components/pending"))
const MachineInspection = lazy(() => import("./views/app/MachineInspection/machineList/index"))
const MachineSystem = lazy(() => import("./views/app/MachineInspection/machineSystem/index"))
const Subtable = lazy(() => import("./views/app/MachineInspection/machineList/components/qrList"))
const PlayerView = lazy(() => import("./views/app/MachineInspection/machineList/components/playerView"))

const Chart = lazy(() => import("../src/views/app/chart/chart"));
const Scorecard = lazy(() => import("../src/views/app/scorecard/scorecard"))
const IndividualCard = lazy(() => import("../src/views/app/scorecard/individual"))
const Activitylog = lazy(() => import("./views/app/activityLogs/activitylog"))
const Profile = lazy(() => import("../src/views/app/profile/profile"))

const QRCode = lazy(() => import("./views/app/QRcodescanner/machineList"));
const MachineSubmit = lazy(() => import("./views/app/QRcodescanner/machineList/machineSubmit"));

const Register = lazy(() => import("../src/views/auth/register"))
const Login = lazy(() => import("../src/views/auth/login"))
const ForgotPassword = lazy(() => import("./views/auth/forgotPassword/index"));
const SetPassword = lazy(() => import("./views/auth/forgotPassword/setPassword"));

function App() {
  const navigate = useNavigate();
  let location = useLocation();
  globalRouter.navigate = navigate;
  const id = localStorage.getItem('userId');
  const { setProfile } = useContextHelper();
  const { data: profileData, refetch } = useProfileDetails(id);

  useEffect(() => {
    if (profileData) {
      setProfile(profileData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData])

  useEffect(() => {
    if (id && id !== null) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  //Logout function Start
  const [logout, setLogout] = useState(false);
  const timeout = 900000
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => setLogout(true), timeout);
    };

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    const resetEvents = () => {
      events.forEach(event => {
        window.addEventListener(event, resetTimer);
      });
    };

    resetTimer();
    resetEvents();

    return () => {
      clearTimeout(timer);
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [timeout]);

  useEffect(() => {
    if (logout) {
     
      if (location?.pathname !== "/machine-inspection/machine-system") {
        message.warning('Session Time Out! Please ReLogin.');
        localStorage?.clear();
        window.location.href = '/login';
      }

      console.log('User has been logged out due to inactivity');
    }
  }, [logout]);
  //Logout function end
  return (
    <Suspense fallback={<div className="loader-img"><Image name="mainLoader2.gif" /></div>}>
      <Routes>
        <Route element={<AuthLayouts />}>
          <Route exact path="/" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password" element={<SetPassword />} />

        </Route>
      </Routes>
      <Routes>
        <Route element={<AppLayout initialRefetch={refetch} />}>
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/department-management" element={<DepartmentManagement />} />
          {/* <Route path="/version-management" element={<TemplateVersion />} /> */}
          <Route path="/role" element={<Role />} />
          <Route path="/notification-management" element={<NotificationManagement />} />
          <Route path="/machine-list" element={<MachineList />} />
          <Route path="/machine-list/sub-machine" element={<SubMachineList />} />
          <Route path="/machine-list/archieve" element={<SubArchieve />} />
          <Route path="/machine-list/sub-table" element={<Subtable />} />
          <Route path="/machine-list/player" element={<PlayerView />} />
          <Route path="/machine-inspection" element={<MachineInspection />} />
          <Route path="/inspection-records" element={<InspectionRecord />} />
          <Route path="/inspection-pending" element={<InspectionPending />} />
          <Route path="/machine-inspection/machine-system" element={<MachineSystem />} />
          <Route path="/chart" element={<Chart />} />
          <Route path="/scorecard" element={<Scorecard />} />
          <Route path="/individualcard" element={<IndividualCard />} />
          <Route path="/activitylog" element={<Activitylog />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/machine-inspection/inspection-list" element={<QRCode />} />
          <Route path="/machine-inspection/submit-list" element={<MachineSubmit />} />
          <Route path="/holiday" element={<Holiday />} />
          <Route path="/shift" element={<Shifts />} />
        </Route>
      </Routes>

    </Suspense>
  );
}

export default App;

